@import url('https://fonts.googleapis.com/css2?family=Forum&family=Josefin+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&family=Josefin+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&family=Josefin+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
/* EMU */
*{
  box-sizing: border-box;
  /* min-height: fit-content; */
  /* background-color: aquamarine; */
}
/* EMU END */




/* APP.JS */
.pageContainer{
  font-family: 'Inter';
/* font-family: 'Josefin Sans', sans-serif; */
  background-color: #88feff;
  border: none;
  
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 30px;
  text-decoration: none; */
}
/* APP.JS END*/


/* LAYOUT */
/* NAVBAR */
nav{
  background-color: white;
}
.navbarContainer{
  display: flex;
  flex-direction: row;
  align-items:center;
  align-content: center;
  justify-content: center;
  font-size: 25px;
  padding: 10px;
  margin: 0px;
  /* background-color: red; */
}
.navbarSocialContainer{
  display: flex;
  /* background-color: blue; */
  align-items: center;
}
.navbarLinkContainer{
  display: flex;
  flex-direction: row;
  /* background-color: greenyellow; */
  justify-content: space-around;
  align-items: center;
  /* margin-left: 30px; */
  width: 50%;
}
.socialContainer{
  display: flex;
  padding: 0px;
  /* background-color: greenyellow; */
}
.navbarLinks{
  display: flex;
  text-decoration: none;
  margin: 10px;
  color: black;
  align-content: center;
  margin-left: 0px;
  margin-right: 30px;
  /* background-color: green; */
}
.navbarLinks:visited{
  display: flex;
  color: black;
  font-size: 250px;
}
.navbarLinks:hover{
  transition: 300ms ease-in-out;
  color: coral;
}
.logo{
  display: flex;
  flex-direction: row;
  text-decoration: none;
  /* border: 1px solid black; */
  /* margin-right: 30px; */
}
.logo:hover{
  transition: 350ms ease-in;

}
.navbarLinksMedia{
  display: none;
}
.phoneNumber{
  margin-left: 30px;
  margin-right: 30px;
}
.phoneNumberBIG{
  font-size: 20px;
  display: none;
}

/* NAVBAR END*/
/* LAYOUT END*/



/* HOME */
.homeContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
  height: 600px;
}
.homeContainerLarge{
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  border: 1px solid black;
  gap: 5px;
  padding: 15px;
  margin: 20px;
  justify-content: space-around;
  align-content: space-around;
}
.homeContainerSmall{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  /* gap: 5px; */
  /* align-content: center; */
  /* text-decoration: dotted; */
  justify-content: space-around;
  align-content: space-around;
  gap: 30px;
}
.homeContainerSmallParagraph{
  display: flex;
  font-size: 20px;
  font-weight: bold;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-content: space-around;

}
.homeContainerSmallParagraphBold{
  border: 1px dotted black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
}
/* HOME END*/




/* GALLERY */
.galleryContainer div{
  width: 300px;
  height: 300px;
  background-position: center;
  background-size: cover;
}
.galleryContainer{
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}
.galleryContainerLarge{
  display: grid;
  margin: 20px;
  justify-content: center;
}
.galleryContainerLarge h1{
  display: flex;
  justify-content: center;
  margin: 20px;
}
video{
  width: 300px;
  height: 300px;
  object-fit: cover;
}
/* GALLERY END */




/* CONTACT */
.formstyle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 25px;
  
  
}
.contact{
  display: flex;
  border: none;
  border-bottom: 1px solid black;
  background-color: #88feff;
  font-size: 25px;
  outline: none;
  
}
.inputContainer{
 display: flex;
 gap: 30px;
 border: none;
 background-color: #88feff;
}
.contactInfo{
  width: 300px;
  height: 100px;
  border: none;
  background-color: #88feff;
  border-bottom: 1px solid black;
  font-size: 25px;
  outline: none;

}
button{
  font-family: 'Overpass', sans-serif;
  margin: 5px;
  border: none;
  border-radius: 30px;
  width: 200px;
  outline: none;
  font-size: 25px;
  background-color: white;
}
button:hover{
  transition: 350ms ease-in;
  color: coral;
}

/* CONTACT END*/



/* VALUE PROP */

.headingContainer{
  border: 1px dotted black;
  margin: 10px;
  padding: 10px;
}

.valuepropheader{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
}

/* VALUE PROP END*/


.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Times New Roman', Times, serif;
  /* border-top: solid black 1px; */
  background-image: url("./pages/see-breeze/fuzzyPalm.png");
  background-repeat: no-repeat;
  max-height: fit-content;
}
.toggle-button {
  position: absolute;
  top: .75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}
.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: black;
  border-radius: 10px;
}

@media (max-width: 500px){

  /* NAVBAR  */
  
  .navbarSocial{
  display: none;
}
  .navbarContainer{
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
  }
  .navbarContainer .ul{
    display: flex;
    flex-direction: column;
    
    } 
  .toggle-button{
      display: flex;
      
  }
  .navbarLinks{
      display: none;
      width: 100%;
      
  }
  .socialContainerUL{
    display: none;
    margin-left: auto;
    margin-right: auto;
}
  .navbarLinks.active{
      display: flex;
      font-size: 20px;
      flex-direction: column;
      align-items: center;
      
      /* background-color: fuchsia; */
  }
  .socialContainer{
    display: none;
    width: 100%;
  }
  .socialContainerUL.active{
    display: flex;
    flex-direction: column;
    }
  .navbarSocialContainer{
    display: flex;
    flex-direction: column;
  }
  .navbarLinkContainer{
    display: flex;
    flex-direction: column;
    /* background-color: greenyellow; */
    justify-content: space-around;
    /* width: 100vw; */
    
  }
  .phoneNumberBIG{
    font-size: 20px;
    margin-left: 30px;
    margin-right: 60px;
  }
  .phoneNumber{
    display: none;
  }
  .navbarLinksMedia.active{
    display: flex;
    font-size: 20px;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: black;
    border-radius: 10px;
    margin-left: 0px;
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    
   

}

.navbarLinksMedia:hover{
  transition: 350ms ease-in;
  box-shadow: 10px 10px 8px #888888;
}
.toggle-button:hover{
  transition: 350ms ease-in-out;
  width: 35px;
 }

/* NAVBAR END */ 
  
/* HOME */
.homeContainer{
  /* background-color: blue; */
  height: 1200px;
  display: flex;

  
}
.homeContainerLarge{
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  border: 1px solid black;
  gap: 5px;
  padding: 15px;
  margin: 20px;
  /* height: 500px; */
  justify-content: space-around;
  align-content: space-around;
}


/* HOMER END */



/* GALLERY */
.galleryContainer{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.galleryContainerLarge{
  display: grid;
  margin: 70px;
  justify-content: center;
}
.galleryContainerLarge h1{
  display: flex;
  justify-content: center;
 }
video{
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.galleryContainer div{
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
}
/* GALLERY END */




/* CONTACT */
.contactLayout{
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 600px;
}
.formstyle{
  /* font-family: 'Forum', cursive;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  gap: 75px;
  padding: 15px;
  border: 1px solid black;
  height: 500px; */
}
input{
  font-family: 'Forum', cursive;
  margin: 5px;
  border: none;
  border-bottom: 1px solid black;
  width: 50px;
  font-size: 20px;
  outline: none;
  background-color: #88feff;

}
.contact{
  width: 100px;
}
.contactInfo{
 width: 300px; 
}
select{
  font-family: 'Forum', cursive;
  background-color: #88feff;
  margin: 5px;
  border: none;
  border-bottom: 1px solid black;
  width: 100px;
  outline: none;
  font-size: 20px;
}
button{
  font-family: 'Forum', cursive;
  margin: 5px;
  border: none;
  width: 200px;
  outline: none;
  font-size: 30px;
  background-color: white;
}
button:hover{
  transition: 350ms ease-in;
  box-shadow: 10px 10px 8px #888888;
  color: salmon
}
/* CONTACT */


}

@media (max-width: 900px){
  .galleryContainer div{
    width: 250px;
    height: 250px;
    background-position: center;
    background-size: cover;
  }
  video{
    width: 250px;
    height: 250px;
    object-fit: cover;
  }
}

@media (max-width: 800px){
  .galleryContainer div{
    width: 200px;
    height: 200px;
    background-position: center;
    background-size: cover;
  }
  video{
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}

.error{
  color: red;
}

